import { toast } from "react-toastify";
import { config } from "../../../core/config/config";

const base_url = config.baseUrl;
const endpoint = "/codigos";


async function ValidarCodigo(codigo) {
    try {
        // console.log(`${base_url + endpoint}/${codigo}`);
        const url = `${base_url + endpoint}/${codigo}`;
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al obtener los datos');
        }

        if (response.ok) {
            toast.success('Codigo valido', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return { success: true, data: result };
        }

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}

async function SubirArchivos(formData) {
    try {
        const url = `${base_url + endpoint}/upload-file`;
        const response = await fetch(url, {
            method: 'POST',
            body: formData
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al subir los archivos');
        }

        if (response.ok) {
            toast.success('Archivos guardados', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return { success: true, data: result };
        }

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}

async function guardarFormulario(formData) {
    try {
        const url = `${base_url}/form`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al guardar el formulario');
        }

        if (response.ok) {
            toast.success('Formulario guardado', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return { success: true, data: result };
        }

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}

async function BorrarArchivo(nameFile) {
    try {
        const url = `${base_url + endpoint}/delete-file`;
        const response = await fetch(url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fileName: nameFile })
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al eliminar el archivo');
        }

        if (response.ok) {
            toast.success('Archivo eliminado', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            return { success: true, data: result };
        }

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}

async function ObtenerFormulario() {
    try {
        const token = localStorage.getItem("codigoFormulario");
        const headers = {
            "Content-Type": "application/json"
        }
        
        const url = `${base_url + endpoint}/obtener-info/${token}`;

        const response = await fetch(url, {
            method: "GET",
            headers: headers
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al obtener los datos');
        }

        return { success: true, data: result };

    } catch (error) {
        return { success: false, message: error.message };
    }
}


export default {
    ValidarCodigo,
    SubirArchivos,
    BorrarArchivo,
    guardarFormulario,
    ObtenerFormulario
};