export const ParseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const ValidarFecha = (fechaInicio, fechaFin) => {
    const mesInicio = parseInt(fechaInicio.split("-")[0]);
    const agnoInicio = parseInt(fechaInicio.split("-")[1]);

    const mesFin = parseInt(fechaFin.split("-")[0]);
    const agnoFin = parseInt(fechaFin.split("-")[1]);

    const today = new Date();
    const year = parseInt(today.getFullYear());
    const month = parseInt(today.getMonth() + 1);

    // console.log("Fecha inicio:", mesInicio, agnoInicio);
    // console.log("Fecha fin:", mesFin, agnoFin);
    // console.log("Fecha actual:", month, year);

    /**El año no puede ser mayor al actual */
    if (agnoInicio > year || agnoFin > year) {
        return false;
    }

    if (agnoInicio > agnoFin) {
        return false;
    }

    if (agnoFin < agnoInicio) {
        return false;
    }

    if(agnoInicio == agnoFin){
        if(mesInicio > mesFin ){
            return false;
        }
    }

    return true;
}
