import React from 'react';

import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import Api from './services/FormServices'
import { config } from "../../core/config/config";

const base_url_files = config.baseUrlFiles;

import DisplayLocalStorageData from "../../core/utils/displayItems";

import logo from '../../assets/img/logo.png'
import 'react-toastify/dist/ReactToastify.css';
import showToast from '../../core/utils/showToast';
import { ValidarFecha } from '../../core/utils/functions';

function Form() {

    const [progress, setProgress] = useState(0);
    const [validateCode, setValidateCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInfoMarital, setShowInfoMarital] = useState(false);
    const [showCantHijos, setShowCantHijos] = useState(false);
    const [cantHijos, setCantHijos] = useState(0);
    const [showStatus, setShowStatus] = useState(false);
    const [showInfoAdjuntos, setShowInfoAdjuntos] = useState(0);
    const [showIncluir, setShowIncluir] = useState(0);
    const [enUsa, setEnUsa] = useState([]);
    const [enusaAdjuntos, setEnusaAdjuntos] = useState([]);
    const [datosExistentes, setDatosExistentes] = useState(false);
    const [maxDate, setMaxDate] = useState('');

    const { register: registerCode, handleSubmit: handleSubmitCode, reset: resetCode, formState: { errors: errorsCode } } = useForm();

    const storedCodigoFormulario = localStorage.getItem('codigoFormulario') || "";
    const storedItems = JSON.parse(localStorage.getItem('items')) || [];
    const storedItemsForm2 = JSON.parse(localStorage.getItem('items2')) || [];
    const storedItemsForm3 = JSON.parse(localStorage.getItem('items3')) || [];
    const storedItemsForm4 = JSON.parse(localStorage.getItem('items4')) || [];
    const storedItemsForm5 = JSON.parse(localStorage.getItem('items5')) || [];
    const storedItemsForm6 = JSON.parse(localStorage.getItem('items6')) || [];
    const storedItemsForm7 = JSON.parse(localStorage.getItem('items7')) || [];
    const storedItemsForm8 = JSON.parse(localStorage.getItem('items8')) || [];
    const storedItemsForm9 = JSON.parse(localStorage.getItem('items9')) || [];
    const storedItemsForm10 = JSON.parse(localStorage.getItem('items10')) || [];
    const storedItemsForm11 = JSON.parse(localStorage.getItem('items11')) || [];
    const storedItemsForm12 = JSON.parse(localStorage.getItem('items12')) || [];

    const [codigoFormulario, setCodigoFormulario] = useState(storedCodigoFormulario);
    const [items, setItems] = useState(storedItems);
    const [items2, setItems2] = useState(storedItemsForm2);
    const [items3, setItems3] = useState(storedItemsForm3);
    const [items4, setItems4] = useState(storedItemsForm4);
    const [items5, setItems5] = useState(storedItemsForm5);
    const [items6, setItems6] = useState(storedItemsForm6);
    const [items7, setItems7] = useState(storedItemsForm7);
    const [items8, setItems8] = useState(storedItemsForm8);
    const [items9, setItems9] = useState(storedItemsForm9);
    const [items10, setItems10] = useState(storedItemsForm10);
    const [items11, setItems11] = useState(storedItemsForm11);
    const [items12, setItems12] = useState(storedItemsForm12);

    const { register: registerForm1, handleSubmit: handleSubmitForm1, reset: reset1, formState: { errors: errors } } = useForm();
    const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: reset2, formState: { errors: errors2 } } = useForm();
    const { register: registerForm3, handleSubmit: handleSubmitForm3, reset: reset3, formState: { errors: errors3 } } = useForm();
    const { register: registerForm4, handleSubmit: handleSubmitForm4, reset: reset4, formState: { errors: errors4 } } = useForm();
    const { register: registerForm5, handleSubmit: handleSubmitForm5, reset: reset5, formState: { errors: errors5 } } = useForm();
    const { register: registerForm6, handleSubmit: handleSubmitForm6, reset: reset6, resetField: resetField6, formState: { errors: errors6 } } = useForm();
    const { register: registerForm7, handleSubmit: handleSubmitForm7, reset: reset7, resetField: resetField7, formState: { errors: errors7 } } = useForm();
    const { register: registerForm8, handleSubmit: handleSubmitForm8, reset: reset8, formState: { errors: errors8 } } = useForm();
    const { register: registerForm9, handleSubmit: handleSubmitForm9, reset: reset9, formState: { errors: errors9 } } = useForm();
    const { register: registerForm10, handleSubmit: handleSubmitForm10, reset: reset10, formState: { errors: errors10 } } = useForm();
    const { register: registerForm11, handleSubmit: handleSubmitForm11, reset: reset11, formState: { errors: errors11 } } = useForm();
    const { register: registerForm12, handleSubmit: handleSubmitForm12, reset: reset12, formState: { errors: errors12 } } = useForm();

    const onSubmitForm1 = async (data) => {

        const formData = new FormData();

        if (data.id[0]) formData.append('archivos', data.id[0], `id_${data.id[0].name}`);
        if (data.pasaporte[0]) formData.append('archivos', data.pasaporte[0], `pasaporte_${data.pasaporte[0].name}`);
        if (data.visa[0]) formData.append('archivos', data.visa[0], `visa_${data.visa[0].name}`);
        if (data.documentosFrontera[0]) formData.append('archivos', data.documentosFrontera[0], `documentosFrontera_${data.documentosFrontera[0].name}`);
        if (data.parole[0]) formData.append('archivos', data.parole[0], `parole_${data.parole[0].name}`);
        if (data.actaNacimiento[0]) formData.append('archivos', data.actaNacimiento[0], `actaNacimiento_${data.actaNacimiento[0].name}`);

        const response = await Api.SubirArchivos(formData);

        delete data.id;
        delete data.pasaporte;
        delete data.visa;
        delete data.documentosFrontera;
        delete data.parole;
        delete data.actaNacimiento;

        const newItem = {
            ...data,
            archivos: response.data.archivos || []
        };

        const newItems = [...items, newItem];

        setItems(newItems);
        localStorage.setItem('items', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset1();
    }

    const onSubmitForm2 = (data) => {

        const fechaInicio = data.mesLlegada + "-" + data.agnoLlegada;
        const fechaFin = data.mesSalida + "-" + data.agnoSalida;

        const res = ValidarFecha(fechaInicio, fechaFin);
        if (!res) {
            showToast("Las fechas no son correctas", "error")
            return;
        }

        const newItems = [...items2, data];
        setItems2(newItems);
        localStorage.setItem('items2', JSON.stringify(newItems));

        showToast("¡Registro guardado con éxito!");

        reset2();
    }

    const onSubmitForm3 = (data) => {

        const fechaInicio = data.mesInicio + "-" + data.agnoInicio;
        const fechaFin = data.mesFin + "-" + data.agnoFin;

        const res = ValidarFecha(fechaInicio, fechaFin);
        if (!res) {
            showToast("Las fechas no son correctas", "error")
            return;
        }

        const newItems = [...items3, data];
        setItems3(newItems);
        localStorage.setItem('items3', JSON.stringify(newItems));

        showToast("¡Registro guardado con éxito!");

        reset3();
    }

    const onSubmitForm4 = (data) => {

        const fechaInicio = data.mesInicio + "-" + data.agnoInicio;
        const fechaFin = data.mesFin + "-" + data.agnoFin;

        const res = ValidarFecha(fechaInicio, fechaFin);
        if (!res) {
            showToast("Las fechas no son correctas", "error")
            return;
        }

        const newItems = [...items4, data];
        setItems4(newItems);
        localStorage.setItem('items4', JSON.stringify(newItems));

        showToast("¡Registro guardado con éxito!");

        reset4();
    }

    const onSubmitForm5 = (data) => {
        const newItems = [...items5, data];
        setItems5(newItems);
        localStorage.setItem('items5', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setShowStatus(false)
        reset5();
    }

    const onSubmitForm6 = async (data) => {

        const formData = new FormData();

        if (data.id?.[0] && data.id[0]) formData.append('archivos', data.id[0], `id_${data.id[0].name}`);
        if (data.pasaporte?.[0] && data.pasaporte[0]) formData.append('archivos', data.pasaporte[0], `pasaporte_${data.pasaporte[0].name}`);
        if (data.visa?.[0] && data.visa[0]) formData.append('archivos', data.visa[0], `visa_${data.visa[0].name}`);
        if (data.documentosFrontera?.[0] && data.documentosFrontera[0]) formData.append('archivos', data.documentosFrontera[0], `documentosFrontera_${data.documentosFrontera[0].name}`);
        if (data.parole?.[0] && data.parole[0]) formData.append('archivos', data.parole[0], `parole_${data.parole[0].name}`);
        if (data.actaNacimiento?.[0] && data.actaNacimiento[0]) formData.append('archivos', data.actaNacimiento[0], `actaNacimiento_${data.actaNacimiento[0].name}`);
        if (data.actaMatrimonio?.[0] && data.actaMatrimonio[0]) formData.append('archivos', data.actaMatrimonio[0], `actaMatrimonio_${data.actaMatrimonio[0].name}`);

        const response = await Api.SubirArchivos(formData);

        delete data.id;
        delete data.pasaporte;
        delete data.visa;
        delete data.documentosFrontera;
        delete data.parole;
        delete data.actaNacimiento;
        delete data.actaMatrimonio;

        const newItem = {
            ...data,
            archivos: response.data.archivos || []
        };

        const newItems = [...items6, newItem];

        setItems6(newItems);
        localStorage.setItem('items6', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setShowInfoAdjuntos(0);
        setShowIncluir(0);
        reset6();
    }

    const onSubmitForm7 = async (data) => {
        const cantidadHijos = data.cantidadhijos;

        var transformData;
        var newItems;
        if (cantHijos > 0) {
            transformData = await Promise.all(
                Array.from({ length: cantidadHijos }, async (_, index) => {
                    const formData = new FormData();

                    //data.id[index]?.[0]
                    let cantArchivos = 0;
                    if (data.id && data.id[index] && data.id[index][0]) {
                        formData.append('archivos', data.id[index][0], `id_${data.id[index][0].name}`);
                        cantArchivos++;
                    }
                    if (data.pasaporte && data.pasaporte[index] && data.pasaporte[index][0]) {
                        formData.append('archivos', data.pasaporte[index][0], `pasaporte_${data.pasaporte[index][0].name}`);
                        cantArchivos++;
                    }
                    if (data.visa && data.visa[index] && data.visa[index][0]) {
                        formData.append('archivos', data.visa[index][0], `visa_${data.visa[index][0].name}`);
                        cantArchivos++;
                    }
                    if (data.documentosFrontera && data.documentosFrontera[index] && data.documentosFrontera[index][0]) {
                        formData.append('archivos', data.documentosFrontera[index][0], `documentosFrontera_${data.documentosFrontera[index][0].name}`);
                        cantArchivos++;
                    }
                    if (data.parole && data.parole[index] && data.parole[index][0]) {
                        formData.append('archivos', data.parole[index][0], `parole_${data.parole[index][0].name}`);
                        cantArchivos++;
                    }
                    if (data.actaNacimiento && data.actaNacimiento[index] && data.actaNacimiento[index][0]) {
                        formData.append('archivos', data.actaNacimiento[index][0], `actaNacimiento_${data.actaNacimiento[index][0].name}`);
                        cantArchivos++;
                    }
                    if (data.actaMatrimonio && data.actaMatrimonio[index] && data.actaMatrimonio[index][0]) {
                        formData.append('archivos', data.actaMatrimonio[index][0], `actaMatrimonio_${data.actaMatrimonio[index][0].name}`);
                        cantArchivos++;
                    }

                    let archivos = [];
                    if (cantArchivos > 0) {
                        const response = await Api.SubirArchivos(formData);

                        delete data.id[index];
                        delete data.pasaporte[index];
                        delete data.visa[index];
                        delete data.documentosFrontera[index];
                        delete data.parole[index];
                        delete data.actaNacimiento[index];
                        delete data.actaMatrimonio[index];

                        archivos = response.data.archivos || null;
                    }

                    const datos = {
                        hijos: data.hijos,
                        primerNombre: data.primerNombre[index],
                        segundoNombre: data.segundoNombre[index],
                        primerApellido: data.primerApellido[index],
                        segundoApellido: data.segundoApellido[index],
                        fechaNacimiento: data.fechaNacimiento[index],
                        ciudadNacimiento: data.ciudadNacimiento[index],
                        paisNacimiento: data.paisNacimiento[index],
                        nacionalidad: data.nacionalidad[index],
                        estadoMarital: data.estadoMarital[index],
                        enUsa: data.enUsa[index],
                        incluirPersona: data?.incluirPersona?.[index] || null,
                        ciudadActual: data?.ciudadActual?.[index] || null,
                        paisActual: data?.paisActual?.[index] || null
                    };

                    return {
                        ...datos,
                        archivos: archivos
                    };
                })
            );
            newItems = [...items7, ...transformData]
        } else {
            const datos = {
                hijos: data.hijos,
                primerNombre: "",
                segundoNombre: "",
                primerApellido: "",
                segundoApellido: "",
                fechaNacimiento: "",
                ciudadNacimiento: "",
                paisNacimiento: "",
                nacionalidad: "",
                estadoMarital: "",
                enUsa: "",
                ciudadActual: "",
                paisActual: "",
                archivos: []
            };
            newItems = [...items7, datos]
        }

        setItems7(newItems);
        localStorage.setItem('items7', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setShowCantHijos(false);
        setCantHijos(0);
        setEnUsa([]);
        setEnusaAdjuntos([]);
        reset7();
    };

    const onSubmitForm8 = (data) => {

        const today = new Date();
        const year = parseInt(today.getFullYear());
        const month = parseInt(today.getMonth() + 1);

        const fechaInicio = data.mes + "-" + data.agno;
        const fechaFin = data.month + "-" + data.year;

        const res = ValidarFecha(fechaInicio, fechaFin);
        if (!res) {
            showToast("Las fechas no son correctas", "error")
            return;
        }

        const newItems = [...items8, data];
        setItems8(newItems);
        localStorage.setItem('items8', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset8();
    }

    const onSubmitForm9 = (data) => {

        const newItems = [...items9, data];
        setItems9(newItems);
        localStorage.setItem('items9', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset9();
    }

    const onSubmitForm10 = (data) => {
        const newItems = [...items10, data];
        setItems10(newItems);
        localStorage.setItem('items10', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset10();
    }

    const onSubmitForm11 = (data) => {
        const newItems = [...items11, data];
        setItems11(newItems);
        localStorage.setItem('items11', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset11();
    }

    const onSubmitForm12 = (data) => {
        const newItems = [...items12, data];
        setItems12(newItems);
        localStorage.setItem('items12', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset12();
    }

    const handdleValidateCode = async (datos) => {
        setLoading(true);
        const codigo = datos.codigo.trim();
        const response = await Api.ValidarCodigo(codigo);
        if (!response.success) {
            setLoading(false);
            return;
        }

        setCodigoFormulario(datos.codigo);
        localStorage.setItem('codigoFormulario', datos.codigo);
        setValidateCode(true);
        loadData();
        setLoading(false);
    }

    const showInformacionMarital = (value) => {
        if (value === "casado/a") {
            setShowInfoMarital(true)
        } else {
            setShowInfoMarital(false)
        }
    }

    const showCantidadHijos = (value) => {
        if (value === "si") {
            setShowCantHijos(true)
        } else {
            setShowCantHijos(false)
            setCantHijos(0);
        }
    }

    const showEnUsa = (value) => {
        if (value === "si") {
            setShowIncluir(1)
        } else if (value === "no") {
            setShowIncluir(2)
        } else {
            setShowIncluir(0)
        }

        resetField6("incluirPersona");
        setShowInfoAdjuntos(0)
    }
    const showAdjuntos = (value) => {

        if (value === "si") {
            setShowInfoAdjuntos(1)
        } else if (value === "no") {
            setShowInfoAdjuntos(2)
        } else {
            setShowInfoAdjuntos(0)
        }
    }

    const showEnUsaHijos = (index, value) => {
        setEnUsa((prevItems) => {
            const newItems = [...prevItems];

            if (newItems[index]) {
                newItems[index].value = value;
            } else {
                newItems[index] = { index, value };
            }

            resetField7(`incluirPersona[${index}]`);
            delete enusaAdjuntos[index];

            return newItems;
        });
    }

    const showAdjuntosHijos = (index, value) => {
        setEnusaAdjuntos((prevItems) => {
            const newItems = [...prevItems];

            if (newItems[index]) {
                newItems[index].value = value;
            } else {
                newItems[index] = { index, value };
            }

            return newItems;
        });
    }

    const cantidadHijos = (e) => {
        const valor = parseInt(e.target.value, 10);
        if (!isNaN(valor)) {
            setCantHijos(valor);
        } else {
            setCantHijos(0);
        }
    };

    const calculateProgress = () => {
        const totalItems = 11;
        let filledItems = 0;

        for (let i = 1; i <= totalItems; i++) {
            let item = localStorage.getItem(`items${i}`);
            if (i == 1) {
                item = localStorage.getItem(`items`);
            }

            if (item && item !== "[]") {
                filledItems++;
            }
        }

        return Math.round((filledItems / totalItems) * 100);
    };

    const salir = () => {
        const keysToRemove = ['items', 'items2', 'items3', 'items4', 'items5', 'items6', 'items7', 'items3', 'items8', 'items9', 'items10', 'items11', 'items12'];

        keysToRemove.forEach(async key => {
            localStorage.removeItem(key);
            setProgress(0);
        });

        setItems([]);
        setItems2([]);
        setItems3([]);
        setItems4([]);
        setItems5([]);
        setItems6([]);
        setItems7([]);
        setItems8([]);
        setItems9([]);
        setItems10([]);
        setItems11([]);
        setItems12([]);

        localStorage.removeItem("codigoFormulario");
        setValidateCode(false)
    }

    const limpiarFormulario = async (e) => {
        const keysToRemove = ['items', 'items2', 'items3', 'items4', 'items5', 'items6', 'items7', 'items3', 'items8', 'items9', 'items10', 'items11', 'items12'];

        keysToRemove.forEach(async key => {

            /** REMOVE FILES */
            const res = localStorage.getItem(key);
            const itemsArray = res ? JSON.parse(res) : [];

            if (Array.isArray(itemsArray)) {
                await Promise.all(
                    itemsArray.map(async (item, index) => {
                        if (Array.isArray(item.archivos)) {
                            return Promise.all(
                                item.archivos.map(async (archivo, archivoIndex) => {
                                    await Api.BorrarArchivo(archivo.nombreArchivo);
                                })
                            );
                        }
                    })
                );
            }
            localStorage.removeItem(key);
            setProgress(0);
        });

        setItems([]);
        setItems2([]);
        setItems3([]);
        setItems4([]);
        setItems5([]);
        setItems6([]);
        setItems7([]);
        setItems8([]);
        setItems9([]);
        setItems10([]);
        setItems11([]);
        setItems12([]);

        toast.success("¡Registros eliminados con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const limpiarFormularioFinal = async (e) => {
        const keysToRemove = ['items', 'items2', 'items3', 'items4', 'items5', 'items6', 'items7', 'items3', 'items8', 'items9', 'items10', 'items11', 'items12'];

        keysToRemove.forEach(async key => {
            localStorage.removeItem(key);
            setProgress(0);
        });

        setItems([]);
        setItems2([]);
        setItems3([]);
        setItems4([]);
        setItems5([]);
        setItems6([]);
        setItems7([]);
        setItems8([]);
        setItems9([]);
        setItems10([]);
        setItems11([]);
        setItems12([]);
        setValidateCode(false);
        setCodigoFormulario("");
        resetCode();
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const guadrarFormulario = async (e) => {
        setLoading(true);
        const codigoFormulario = localStorage.getItem("codigoFormulario");
        const informacionPersonal = localStorage.getItem("items");
        const direccionesResidencia = localStorage.getItem("items2");
        const estudios = localStorage.getItem("items3");
        const empleos = localStorage.getItem("items4");
        const padresHermanos = localStorage.getItem("items5");
        const informacionMarital = localStorage.getItem("items6");
        const informacionHijos = localStorage.getItem("items7");
        const salidaPais = localStorage.getItem("items8");
        const religion = localStorage.getItem("items9");
        const grupoEtnico = localStorage.getItem("items10");
        const idiomaNativo = localStorage.getItem("items11");
        const otrosIdiomas = localStorage.getItem("items12");

        const newData = {
            codigoFormulario,
            informacionPersonal,
            direccionesResidencia,
            estudios,
            empleos,
            padresHermanos,
            informacionMarital,
            informacionHijos,
            salidaPais,
            religion,
            grupoEtnico,
            idiomaNativo,
            otrosIdiomas
        }
        const response = await Api.guardarFormulario(newData);
        if (!response.success) {
            setLoading(false);
            return;
        }

        await limpiarFormularioFinal();
        setLoading(false);
    }

    const handdleCompareWord = (e) => {
        const buscar = e.target.value;
        const compare = "Estados Unidos";

        const res = buscar.localeCompare(compare, undefined, { sensitivity: 'base' })
        if (res == 0) {
            setShowStatus(true);
        } else {
            setShowStatus(false);
        }
    }

    const loadData = async () => {

        const response = await Api.ObtenerFormulario();
        if (!response.success) {
            return;
        }

        if (response.data.formInformacionPersonal.length > 0) {
            setItems(response.data.formInformacionPersonal);
            setItems2(response.data.formDireccionesResidencia);
            setItems3(response.data.formEstudios);
            setItems4(response.data.formEmpleos);
            setItems5(response.data.formPadresHermanos);
            setItems6(response.data.formInformacionMarital);
            setItems7(response.data.formInformacionHijos);
            setItems8(response.data.formSalidaPais);
            setItems9(response.data.formReligion);
            setItems10(response.data.formGrupoEtnico);
            setItems11(response.data.formIdiomaNativo);
            setItems12(response.data.formOtrosIdiomas);

            localStorage.setItem('items', JSON.stringify(response.data.formInformacionPersonal));
            localStorage.setItem('items2', JSON.stringify(response.data.formDireccionesResidencia));
            localStorage.setItem('items3', JSON.stringify(response.data.formEstudios));
            localStorage.setItem('items4', JSON.stringify(response.data.formEmpleos));
            localStorage.setItem('items5', JSON.stringify(response.data.formPadresHermanos));
            localStorage.setItem('items6', JSON.stringify(response.data.formInformacionMarital));
            localStorage.setItem('items7', JSON.stringify(response.data.formInformacionHijos));
            localStorage.setItem('items8', JSON.stringify(response.data.formSalidaPais));
            localStorage.setItem('items9', JSON.stringify(response.data.formReligion));
            localStorage.setItem('items10', JSON.stringify(response.data.formGrupoEtnico));
            localStorage.setItem('items11', JSON.stringify(response.data.formIdiomaNativo));
            localStorage.setItem('items12', JSON.stringify(response.data.formOtrosIdiomas));

            const progressPercentage = calculateProgress();
            setProgress(progressPercentage);

            setDatosExistentes(true)
        } else {
            setDatosExistentes(false)
        }

    }

    useEffect(() => {

        /**Saber si es casado/a */
        const res = localStorage.getItem('items6');
        const itemsArray = res ? JSON.parse(res) : [];

        if (Array.isArray(itemsArray)) {
            itemsArray.map((item, index) => {
                if (item.estadoMarital == "casado/a") {
                    setShowInfoMarital(true);
                }
            });
        }

        /**Calcular porcenje del formulario */
        const progressPercentage = calculateProgress();
        setProgress(progressPercentage);

        document.body.style.overflow = '';
        document.body.style.paddingRight = '';


        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const currentDate = `${year}-${month}-${day}`;

        setMaxDate(currentDate);

    }, [items, items2, items3, items4, items5, items6, items7, items8, items9, items10, items11, items12, progress, showInfoMarital, enUsa, enusaAdjuntos])


    return (
        <>
            <ToastContainer />
            <div className="fixedArea">
                <nav className={`navbar myNavBar navbar-expand-lg navbar-dark bg-transparent active`}>
                    <div className="container">
                        <NavLink className={`navbar-brand fs-4`} to={`/`}>Asilo a tu alcance</NavLink>
                        <button className="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="sidebar offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header text-white border-bottom">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Asilo a tu alcance</h5>
                                <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-flex flex-column flex-lg-row p-4 p-lg-0 justify-content-end">
                                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3">
                                    <NavLink to={`/login`} target="_blank" className={`text-white text-decoration-none px-3 py-1 rounded-4`} style={{ backgroundColor: '#B1802D' }}>Iniciar sesión</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <section className="sectionForm">
                <div className="container">
                    {
                        validateCode ? (
                            <main className="pb-4 pt-5">
                                <div className="mt-5 mb-4 text-center">
                                    <img className="d-block mx-auto mb-4" src={logo} alt="" width="100" height="90" />
                                    <h2 className="fw-bold">Formulario de inscripción</h2>
                                    <p className="lead">Para dar continuidad con su proceso, es necesario que diligencie todos los campos del formulario.</p>
                                </div>
                                <div className="progress m-3">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated fw-bold" role="progressbar" style={{ "width": `${progress}%`, "backgroundColor": "#B1802D" }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                                </div>
                                <div className="accordion shadow mt-4" id="accordionInformacion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                                <span>1. Información personal</span>
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sección completada"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseZero" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm1(onSubmitForm1)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>La siguiente información debe de ser del titular del proceso</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="primerNombre" className="form-label">Primer nombre</label>
                                                                                <input {...registerForm1("primerNombre", { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors.primerNombre ? 'is-invalid' : ''}`} id="primerNombre" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.primerNombre?.message}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="segundoNombre" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                                <input {...registerForm1("segundoNombre")} type="text" className="form-control" id="segundoNombre" placeholder="" defaultValue="" />
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="primerApellido" className="form-label">Primer apellido</label>
                                                                                <input {...registerForm1("primerApellido", { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors.primerApellido ? 'is-invalid' : ''}`} id="primerApellido" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.primerApellido?.message}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="segundoApellido" className="form-label">Segundo apellido</label>
                                                                                <input {...registerForm1("segundoApellido", { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors.segundoApellido ? 'is-invalid' : ''}`} id="segundoApellido" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.segundoApellido?.message}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="ciudadNacimiento" className="form-label">Ciudad y Pais de nacimiento</label>
                                                                                <input {...registerForm1("ciudadNacimiento", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors.ciudadNacimiento ? 'is-invalid' : ''}`} id="ciudadNacimiento" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.ciudadNacimiento?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="telefono" className="form-label">Numero de telefono<span className="text-body-secondary">(De EEUU)</span></label>
                                                                                <div className="input-group mb-3">
                                                                                    <span className="input-group-text" id="basic-addon1">+1</span>
                                                                                    <input {...registerForm1("telefono", { required: "numero de telefono es obligatorio" })} type="text" className={`form-control ${errors.telefono ? 'is-invalid' : ''}`} id="telefono" placeholder="" aria-describedby="basic-addon1" />
                                                                                </div>
                                                                                <div className="invalid-feedback">
                                                                                    {errors.telefono?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-6">
                                                                                <label htmlFor="tipoIngreso" className="form-label">Tipo de ingreso<span className="text-body-secondary">(Tu ingreso fue con Visa, con Parole, con la aplicación del CBP One o por Frontera con o sin contacto con migración)</span></label>
                                                                                <input {...registerForm1("tipoIngreso", { required: "El tipo de ingreso es obligatorio" })} type="text" className={`form-control ${errors.tipoIngreso ? 'is-invalid' : ''}`} id="tipoIngreso" placeholder="" aria-describedby="basic-addon1" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.tipoIngreso?.message}
                                                                                </div>
                                                                            </div>
                                                                            <fieldset className="border rounded-3 mt-2 p-3">
                                                                                <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1.1rem" }} >Adjuntar archivos</legend>
                                                                                <div className="bd-callout bd-callout-info shadow-sm mt-0" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                    <h5>Tenga en cuenta</h5>
                                                                                    <p>Subir los documentos correspondientes que apliquen para su caso</p>
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="id" className="form-label">ID</label>
                                                                                        <input {...registerForm1("id")} className={`form-control ${errors.cedula ? 'is-invalid' : ''}`} type="file" id="id" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.id?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="pasaporte" className="form-label">Pasaporte</label>
                                                                                        <input {...registerForm1("pasaporte")} className={`form-control ${errors.pasaporte ? 'is-invalid' : ''}`} type="file" id="pasaporte" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.pasaporte?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="visa" className="form-label">Visa</label>
                                                                                        <input {...registerForm1("visa")} className={`form-control ${errors.visa ? 'is-invalid' : ''}`} type="file" id="visa" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.visa?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="documentosFrontera" className="form-label">Documentos entregados en frontera</label>
                                                                                        <input {...registerForm1("documentosFrontera")} className={`form-control ${errors.cedula ? 'is-invalid' : ''}`} type="file" id="documentosfrontera" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.documentosFrontera?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="parole" className="form-label">parole</label>
                                                                                        <input {...registerForm1("parole")} className={`form-control ${errors.parole ? 'is-invalid' : ''}`} type="file" id="parole" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.parole?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="actaNacimiento" className="form-label">Acta de nacimiento</label>
                                                                                        <input {...registerForm1("actaNacimiento")} className={`form-control ${errors.actaNacimiento ? 'is-invalid' : ''}`} type="file" id="actaNacimiento" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.actaNacimiento?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                        <hr className="my-4" />
                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Primer nombre</th>
                                                                                    <th scope="col">Segundo nombre</th>
                                                                                    <th scope="col">Primer apellido</th>
                                                                                    <th scope="col">Segundo apellido</th>
                                                                                    <th scope="col">Ciudad de nacimiento</th>
                                                                                    <th scope="col">Numero de telefono</th>
                                                                                    <th scope="col">Tipo de ingreso</th>
                                                                                    <th scope="col">Archivos</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items} setItems={setItems} localStorageItem={'items'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                2. Direcciones de residencia de los ultimos 5 años
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items2.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                        <h5>Tenga en cuenta</h5>
                                                        <p>Proporcione la información de las direcciones en las que ha recidido en los ultimos 5 años, incluyendo las direcciones en Estados Unidos</p>
                                                    </div>
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm2(onSubmitForm2)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="col-sm-4">
                                                                    <label htmlFor="direccion" className="form-label">Dirección</label>
                                                                    <input {...registerForm2("direccion", { required: "La dirección es obligatoria" })} type="text" className={`form-control ${errors2.direccion ? 'is-invalid' : ''}`} id="direccion" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.direccion?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="numeroApartamento" className="form-label">Numero de Apartamento</label>
                                                                    <input {...registerForm2("numeroApartamento", { required: "El numero de apartamento es obligatorio" })} type="text" className={`form-control ${errors2.numeroApartamento ? 'is-invalid' : ''}`} id="numeroApartamento" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.numeroApartamento?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="ciudad" className="form-label">Ciudad</label>
                                                                    <input {...registerForm2("ciudad", { required: "La Ciudad es obligatoria" })} type="text" className={`form-control ${errors2.ciudad ? 'is-invalid' : ''}`} id="ciudad" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.ciudad?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="estadoProvincia" className="form-label">Estado/Provincia</label>
                                                                    <input {...registerForm2("estadoProvincia", { required: "El Estado/Provincia es obligatorio" })} type="text" className={`form-control ${errors2.estadoProvincia ? 'is-invalid' : ''}`} id="estadoProvincia" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.estadoProvincia?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="pais" className="form-label">Pais</label>
                                                                    <input {...registerForm2("pais", { required: "El pais es obligatorio" })} type="text" className={`form-control ${errors2.pais ? 'is-invalid' : ''}`} id="pais" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.pais?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="codigoPostal" className="form-label">Codigo Postal</label>
                                                                    <input {...registerForm2("codigoPostal", { required: "El codigo postal es obligatorio" })} type="text" className={`form-control ${errors2.codigoPostal ? 'is-invalid' : ''}`} id="codigoPostal" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.codigoPostal?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de llegada</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesLlegada" className="form-label">Mes</label>
                                                                            <select {...registerForm2("mesLlegada", { required: "El mes de llegada es obligatorio" })} className={`form-select ${errors2.mesLlegada ? 'is-invalid' : ''}`} id="mesLlegada">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors2.mesLlegada?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="agnoLlegada" className="form-label">Año</label>
                                                                            <input {...registerForm2("agnoLlegada", { required: "El año de llegada es obligatorio" })} type="number" className={`form-control ${errors2.agnoLlegada ? 'is-invalid' : ''}`} id="agnoLlegada" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors2.agnoLlegada?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de salida</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesSalida" className="form-label">Mes</label>
                                                                            <select {...registerForm2("mesSalida", { required: "El mes de salida es obligatorio" })} className={`form-select ${errors2.mesSalida ? 'is-invalid' : ''}`} id="mesSalida">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors2.mesSalida?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="agnoSalida" className="form-label">Año</label>
                                                                            <input {...registerForm2("agnoSalida", { required: "El año de salida es obligatorio" })} type="number" className={`form-control ${errors2.agnoSalida ? 'is-invalid' : ''}`} id="agnoSalida" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors2.agnoSalida?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items2.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th colSpan={7}></th>
                                                                                    <th colSpan={2}>Fecha de llegada</th>
                                                                                    <th colSpan={2}>Fecha de salida</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Dirección</th>
                                                                                    <th scope="col">Número de Apartamento</th>
                                                                                    <th scope="col">Ciudad</th>
                                                                                    <th scope="col">Estado/Provincia</th>
                                                                                    <th scope="col">Pais</th>
                                                                                    <th scope="col">Codigo Postal</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items2} setItems={setItems2} localStorageItem={'items2'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                3. Estudios realizados y culminados
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items3.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm3(onSubmitForm3)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="institucion" className="form-label">Institución</label>
                                                                    <input {...registerForm3("institucion", { required: "La institución es obligatoria." })} type="text" className={`form-control ${errors3.institucion ? 'is-invalid' : ''}`} id="institucion" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors3.institucion?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="nivelAcademico" className="form-label">Nivel academico</label>
                                                                    <select {...registerForm3("nivelAcademico", { required: "El nivel academico es obligatorio" })} className={`form-select ${errors3.nivelAcademico ? 'is-invalid' : ''}`} id="nivelAcademico" required="">
                                                                        <option value="">Seleccione...</option>
                                                                        <option value="primaria">Primaria</option>
                                                                        <option value="secundaria">Secundaria</option>
                                                                        <option value="universidad">Universidad</option>
                                                                        <option value="tecnico">Tecnico</option>
                                                                        <option value="tecnologo">Tecnologo</option>
                                                                        <option value="especialización">Especialización</option>
                                                                        <option value="mestria">Mestria</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        {errors3.nivelAcademico?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="ciudad" className="form-label">Ciudad</label>
                                                                    <input {...registerForm3("ciudad", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors3.ciudad ? 'is-invalid' : ''}`} id="ciudad" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors3.ciudad?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="pais" className="form-label">Pais</label>
                                                                    <input {...registerForm3("pais", { required: "El pais es obligatorio" })} type="text" className={`form-control ${errors3.pais ? 'is-invalid' : ''}`} id="pais" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors3.pais?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de inicio</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesInicio" className="form-label">Mes</label>
                                                                            <select {...registerForm3("mesInicio", { required: "El mes de inicio es obligatorio" })} className={`form-select ${errors3.mesInicio ? 'is-invalid' : ''}`} id="mesInicio">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors3.mesInicio?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="agnoInicio" className="form-label">Año</label>
                                                                            <input {...registerForm3("agnoInicio", { required: "El año de inicio es obligatorio" })} type="number" className={`form-control ${errors3.agnoInicio ? 'is-invalid' : ''}`} id="agnoInicio" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors3.agnoInicio?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de culminación</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesFin" className="form-label">Mes</label>
                                                                            <select {...registerForm3("mesFin", { required: "El mes de culminacion es obligatorio" })} className={`form-select ${errors3.mesFin ? 'is-invalid' : ''}`} id="mesFin">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors3.mesFin?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="agnoFin" className="form-label">Año</label>
                                                                            <input {...registerForm3("agnoFin", { required: "El año de culminación es obligatorio" })} type="number" className={`form-control ${errors3.agnoFin ? 'is-invalid' : ''}`} id="agnoFin" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors3.agnoFin?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items3.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th colSpan={5}></th>
                                                                                    <th colSpan={2}>Fecha de inicio</th>
                                                                                    <th colSpan={2}>Fecha de culminación</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Institución</th>
                                                                                    <th scope="col">Nivel academico</th>
                                                                                    <th scope="col">Ciudad</th>
                                                                                    <th scope="col">Pais</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items3} setItems={setItems3} localStorageItem={'items3'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                4. Empleos de los ultimos 5 años
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items4.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                        <h5>Tenga en cuenta</h5>
                                                        <p>Proporcione la información de los empleos que ha tenido en los ultimos 5 años, incluyendo los trabajos realizados en Estados Unidos, si aplica</p>
                                                    </div>
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm4(onSubmitForm4)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="nombreEmpresa" className="form-label">Nombre de la empresa</label>
                                                                    <input {...registerForm4("nombreEmpresa", { required: "El nombre de la empresa es obligatorio." })} type="text" className={`form-control ${errors4.nombreEmpresa ? 'is-invalid' : ''}`} id="nombreEmpresa" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.nombreEmpresa?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="cargoOcupacion" className="form-label">Cargo/Ocupación</label>
                                                                    <input {...registerForm4("cargoOcupacion", { required: "El Cargo/Ocupación es obligatorio" })} type="text" className={`form-control ${errors4.cargoOcupacion ? 'is-invalid' : ''}`} id="cargoOcupacion" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.cargoOcupacion?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="ciudad" className="form-label">Ciudad</label>
                                                                    <input {...registerForm4("ciudad", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors4.ciudad ? 'is-invalid' : ''}`} id="ciudad" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.ciudad?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="pais" className="form-label">Pais</label>
                                                                    <input {...registerForm4("pais", { required: "El pais es obligatorio" })} type="text" className={`form-control ${errors4.pais ? 'is-invalid' : ''}`} id="pais" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.pais?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de inicio</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesInicio" className="form-label">Mes</label>
                                                                            <select {...registerForm4("mesInicio", { required: "El mes de inicio es obligatorio" })} className={`form-select ${errors4.mesInicio ? 'is-invalid' : ''}`} id="mesInicio">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors4.mesInicio?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="agnoInicio" className="form-label">Año</label>
                                                                            <input {...registerForm4("agnoInicio", { required: "El año de inicio es obligatorio" })} type="number" className={`form-control ${errors4.agnoInicio ? 'is-invalid' : ''}`} id="agnoInicio" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors4.agnoInicio?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de culminación</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesFin" className="form-label">Mes</label>
                                                                            <select {...registerForm4("mesFin", { required: "El mes de culminacion es obligatorio" })} className={`form-select ${errors4.mesFin ? 'is-invalid' : ''}`} id="mesFin">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors4.mesFin?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="agnoFin" className="form-label">Año</label>
                                                                            <input {...registerForm4("agnoFin", { required: "El año de culminación es obligatorio" })} type="number" className={`form-control ${errors4.agnoFin ? 'is-invalid' : ''}`} id="agnoFin" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors4.agnoFin?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items4.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th colSpan={5}></th>
                                                                                    <th colSpan={2}>Fecha de inicio</th>
                                                                                    <th colSpan={2}>Fecha de culminación</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Nombre de la empresa</th>
                                                                                    <th scope="col">Cargo/Ocupación</th>
                                                                                    <th scope="col">Ciudad</th>
                                                                                    <th scope="col">Pais</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items4} setItems={setItems4} localStorageItem={'items4'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                5. Nombres completos de padres y  hermanos de mayor a menor
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items5.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm5(onSubmitForm5)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                    <h5>Tenga en cuenta</h5>
                                                                    <p>Proporcione la informacion de sus hermanos ordenados de mayor a menor, cuando aplique</p>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label htmlFor="parentezco" className="form-label">Parentezco</label>
                                                                    <select {...registerForm5("parentezco", { required: "El parentezco es obligatorio" })} className={`form-select ${errors5.parentezco ? 'is-invalid' : ''}`} id="parentezco" required="">
                                                                        <option value="">Seleccione...</option>
                                                                        <option value="papa">Papá</option>
                                                                        <option value="mama">Mamá </option>
                                                                        <option value="hermano">Hermano</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        {errors5.parentezco?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="primerNombre" className="form-label">Primer nombre</label>
                                                                    <input {...registerForm5("primerNombre", { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors5.primerNombre ? 'is-invalid' : ''}`} id="primerNombre" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.primerNombre?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="segundoNombre" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                    <input {...registerForm5("segundoNombre")} type="text" className="form-control" id="segundoNombre" placeholder="" defaultValue="" />
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="primerApellido" className="form-label">Primer apellido</label>
                                                                    <input {...registerForm5("primerApellido", { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors5.primerApellido ? 'is-invalid' : ''}`} id="primerApellido" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.primerApellido?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="segundoApellido" className="form-label">Segundo apellido</label>
                                                                    <input {...registerForm5("segundoApellido", { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors5.segundoApellido ? 'is-invalid' : ''}`} id="segundoApellido" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.segundoApellido?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="ciudadNacimiento" className="form-label">Ciudad de nacimiento</label>
                                                                    <input {...registerForm5("ciudadNacimiento", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors5.ciudadNacimiento ? 'is-invalid' : ''}`} id="ciudadNacimiento" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.ciudadNacimiento?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="pais" className="form-label">Pais de nacimiento</label>
                                                                    <input {...registerForm5("pais", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors5.pais ? 'is-invalid' : ''}`} id="pais" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.pais?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="ciudadActual" className="form-label">Ciudad donde vive actualmente</label>
                                                                    <input {...registerForm5("ciudadActual", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors5.ciudadActual ? 'is-invalid' : ''}`} id="ciudadActual" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.ciudadActual?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="paisActual" className="form-label">Pais donde vive actualmente</label>
                                                                    <input {...registerForm5("paisActual", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors5.paisActual ? 'is-invalid' : ''}`} id="paisActual" placeholder="" onBlur={handdleCompareWord} />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.paisActual?.message}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                showStatus ? (
                                                                    <>
                                                                        <div className="bd-callout bd-callout-info shadow-sm mt-3" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                            <h5>Nota</h5>
                                                                            <p className='m-0'>Bajo que estatus migratorio se encuentra su familiar dentro de estados unidos</p>
                                                                        </div>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-3 m-0">
                                                                                <label htmlFor="statusMigratorio" className="form-label">Estatus migratorio?</label>
                                                                                <input {...registerForm5("statusMigratorio", { required: "El campo de status migratorio es obligtatorio" })} type="text" className={`form-control ${errors5.statusMigratorio ? 'is-invalid' : ''}`} id="statusMigratorio" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors5.statusMigratorio?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="row g-3"><div className="col-sm-3"></div></div>
                                                                )
                                                            }

                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items5.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Parentezco</th>
                                                                                    <th scope="col">Primer nombre</th>
                                                                                    <th scope="col">Segundo nombre</th>
                                                                                    <th scope="col">Primer apellido</th>
                                                                                    <th scope="col">Segundo apellido</th>
                                                                                    <th scope="col">Ciudad de nacimiento</th>
                                                                                    <th scope="col">Pais de nacimiento</th>
                                                                                    <th scope="col">Ciudad donde vive actualmente</th>
                                                                                    <th scope="col">Pais donde vive actualmente</th>
                                                                                    <th scope="col">Status migratorio</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items5} setItems={setItems5} localStorageItem={'items5'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                6. Información marital
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items6.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm6(onSubmitForm6)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items6.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>El conyugue puede ser incluido dentro de tu proceso siempre y cuando este en estados unidos y haya ingresado de la misma manera en la que tu lo hiciste.</p>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <label htmlFor="estadoMarital" className="form-label">Estado marital</label>
                                                                                <select
                                                                                    {...registerForm6("estadoMarital", { required: "El estado marital es obligatorio" })}
                                                                                    onChange={(e) => {
                                                                                        showInformacionMarital(e.target.value);
                                                                                        registerForm6("estadoMarital").onChange(e);
                                                                                    }}
                                                                                    className={`form-select ${errors6.estadoMarital ? 'is-invalid' : ''}`} id="estadoMarital" required=""
                                                                                >
                                                                                    <option value="">Seleccione...</option>
                                                                                    <option value="soltero/a">Soltero/a</option>
                                                                                    <option value="casado/a">Casado/a</option>
                                                                                    <option value="divorciado/a">Divorciado/a</option>
                                                                                    <option value="viudo/a">Viudo/a</option>
                                                                                </select>
                                                                                <div className="invalid-feedback">
                                                                                    {errors6.estadoMarital?.message}
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                showInfoMarital ? (
                                                                                    <>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="primerNombre" className="form-label">Primer nombre</label>
                                                                                            <input {...registerForm6("primerNombre", { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors6.primerNombre ? 'is-invalid' : ''}`} id="primerNombre" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.primerNombre?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="segundoNombre" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                                            <input {...registerForm6("segundoNombre")} type="text" className="form-control" id="segundoNombre" placeholder="" defaultValue="" />
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="primerApellido" className="form-label">Primer apellido</label>
                                                                                            <input {...registerForm6("primerApellido", { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors6.primerApellido ? 'is-invalid' : ''}`} id="primerApellido" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.primerApellido?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="segundoApellido" className="form-label">Segundo apellido</label>
                                                                                            <input {...registerForm6("segundoApellido", { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors6.segundoApellido ? 'is-invalid' : ''}`} id="segundoApellido" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.segundoApellido?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="fechaNacimiento" className="form-label">Fecha de nacimiento</label>
                                                                                            <input {...registerForm6("fechaNacimiento", { required: "La fecha de nacimiento es obligatoria" })} type="date" className={`form-control ${errors6.fechaNacimiento ? 'is-invalid' : ''}`} id="fechaNacimiento" placeholder="" max={maxDate} />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.fechaNacimiento?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="ciudadNacimiento" className="form-label">Ciudad de nacimiento</label>
                                                                                            <input {...registerForm6("ciudadNacimiento", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors6.ciudadNacimiento ? 'is-invalid' : ''}`} id="ciudadNacimiento" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.ciudadNacimiento?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="paisNacimiento" className="form-label">Pais de nacimiento</label>
                                                                                            <input {...registerForm6("paisNacimiento", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors6.paisNacimiento ? 'is-invalid' : ''}`} id="paisNacimiento" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.paisNacimiento?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="nacionalidad" className="form-label">Nacionalidad</label>
                                                                                            <input {...registerForm6("nacionalidad", { required: "La nacionalidad es obligatoria" })} type="text" className={`form-control ${errors6.nacionalidad ? 'is-invalid' : ''}`} id="nacionalidad" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.nacionalidad?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="fechaMatrimonio" className="form-label">Fecha de matrimonio</label>
                                                                                            <input {...registerForm6("fechaMatrimonio", { required: "La fecha de matrimonio es obligatoria" })} type="date" className={`form-control ${errors6.fechaMatrimonio ? 'is-invalid' : ''}`} id="fechaMatrimonio" placeholder="" max={maxDate} />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.fechaMatrimonio?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="ciudadMatrimonio" className="form-label">Ciudad donde se casaron</label>
                                                                                            <input {...registerForm6("ciudadMatrimonio", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors6.ciudadMatrimonio ? 'is-invalid' : ''}`} id="ciudadMatrimonio" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.ciudadMatrimonio?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="paisMatrimonio" className="form-label">Pais donde se casaron</label>
                                                                                            <input {...registerForm6("paisMatrimonio", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors6.paisMatrimonio ? 'is-invalid' : ''}`} id="paisMatrimonio" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.paisMatrimonio?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="enUsa" className="form-label">Se encuentra en los Estados Unidos?</label>
                                                                                            <select
                                                                                                {...registerForm6("enUsa", { required: "Debe seleccionar si se encuentra en los Estado Unidos" })}
                                                                                                onChange={(e) => {
                                                                                                    showEnUsa(e.target.value);
                                                                                                    registerForm6("enUsa").onChange(e);
                                                                                                }}
                                                                                                className={`form-select ${errors6.enUsa ? 'is-invalid' : ''}`} id="enUsa" required=""
                                                                                            >
                                                                                                <option value="">Seleccione...</option>
                                                                                                <option value="si">Si</option>
                                                                                                <option value="no">No</option>
                                                                                            </select>
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.enUsa?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            showIncluir == 1 && (
                                                                                                <div className="col-sm-3">
                                                                                                    <label htmlFor="incluirPersona" className="form-label">Deseas incluirla dentro de tu proceso?</label>
                                                                                                    <select
                                                                                                        {...registerForm6("incluirPersona", { required: "Debe seleccionar si se encuentra en los Estado Unidos" })}
                                                                                                        onChange={(e) => {
                                                                                                            showAdjuntos(e.target.value);
                                                                                                            registerForm6("incluirPersona").onChange(e);
                                                                                                        }}
                                                                                                        className={`form-select ${errors6.incluirPersona ? 'is-invalid' : ''}`} id="incluirPersona" required=""
                                                                                                    >
                                                                                                        <option value="">Seleccione...</option>
                                                                                                        <option value="si">Si</option>
                                                                                                        <option value="no">No</option>
                                                                                                    </select>
                                                                                                    <div className="invalid-feedback">
                                                                                                        {errors6.incluirPersona?.message}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            showIncluir == 2 || showInfoAdjuntos == 2 ? (
                                                                                                <>
                                                                                                    <div className="col-sm-3">
                                                                                                        <label htmlFor="ciudadActual" className="form-label">Ciudad donde vive actualmente</label>
                                                                                                        <input {...registerForm6("ciudadActual", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors6.ciudadActual ? 'is-invalid' : ''}`} id="ciudadActual" placeholder="" />
                                                                                                        <div className="invalid-feedback">
                                                                                                            {errors6.ciudadActual?.message}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-3">
                                                                                                        <label htmlFor="paisActual" className="form-label">Pais donde vive actualmente</label>
                                                                                                        <input {...registerForm6("paisActual", { required: "El pais donde vive actualmente es obligatorio" })} type="text" className={`form-control ${errors6.paisActual ? 'is-invalid' : ''}`} id="paisActual" placeholder="" onBlur={handdleCompareWord} />
                                                                                                        <div className="invalid-feedback">
                                                                                                            {errors6.paisActual?.message}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (<></>)
                                                                                        }
                                                                                        {
                                                                                            showInfoAdjuntos == 1 && (
                                                                                                <>
                                                                                                    <fieldset className="border rounded-3 mt-2 p-3">
                                                                                                        <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1.1rem" }} >Adjuntar archivos</legend>
                                                                                                        <div className="bd-callout bd-callout-info shadow-sm mt-0" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                                            <h5>Tenga en cuenta</h5>
                                                                                                            <p>Subir los documentos correspondientes que apliquen para su caso</p>
                                                                                                        </div>
                                                                                                        <div className='row'>
                                                                                                            <div className="mb-3 col-sm-6">
                                                                                                                <label htmlFor="id" className="form-label">ID</label>
                                                                                                                <input {...registerForm6("id")} className={`form-control ${errors6.cedula ? 'is-invalid' : ''}`} type="file" id="id" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.id?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3 col-sm-6">
                                                                                                                <label htmlFor="pasaporte" className="form-label">Pasaporte</label>
                                                                                                                <input {...registerForm6("pasaporte")} className={`form-control ${errors6.pasaporte ? 'is-invalid' : ''}`} type="file" id="pasaporte" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.pasaporte?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3 col-sm-6">
                                                                                                                <label htmlFor="visa" className="form-label">Visa</label>
                                                                                                                <input {...registerForm6("visa")} className={`form-control ${errors6.visa ? 'is-invalid' : ''}`} type="file" id="visa" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.visa?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3 col-sm-6">
                                                                                                                <label htmlFor="documentosFrontera" className="form-label">Documentos entregados en frontera</label>
                                                                                                                <input {...registerForm6("documentosFrontera")} className={`form-control ${errors6.cedula ? 'is-invalid' : ''}`} type="file" id="documentosfrontera" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.documentosFrontera?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3 col-sm-6">
                                                                                                                <label htmlFor="parole" className="form-label">parole</label>
                                                                                                                <input {...registerForm6("parole")} className={`form-control ${errors6.parole ? 'is-invalid' : ''}`} type="file" id="parole" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.parole?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3 col-sm-6">
                                                                                                                <label htmlFor="actaNacimiento" className="form-label">Acta de nacimiento</label>
                                                                                                                <input {...registerForm6("actaNacimiento")} className={`form-control ${errors6.actaNacimiento ? 'is-invalid' : ''}`} type="file" id="actaNacimiento" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.actaNacimiento?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="mb-3">
                                                                                                                <label htmlFor="actaMatrimonio" className="form-label">Acta de matrimonio</label>
                                                                                                                <input {...registerForm6("actaMatrimonio")} className={`form-control ${errors6.actaMatrimonio ? 'is-invalid' : ''}`} type="file" id="actaMatrimonio" />
                                                                                                                <div className="invalid-feedback">
                                                                                                                    {errors6.actaMatrimonio?.message}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </fieldset>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (<></>)
                                                                            }

                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items6.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Estado marital</th>
                                                                                    <th scope="col">Primer nombre</th>
                                                                                    <th scope="col">Segundo nombre</th>
                                                                                    <th scope="col">Primer apellido</th>
                                                                                    <th scope="col">Segundo apellido</th>
                                                                                    <th scope="col">Fecha de nacimiento</th>
                                                                                    <th scope="col">Ciudad de nacimiento</th>
                                                                                    <th scope="col">Pais de nacimiento</th>
                                                                                    <th scope="col">Nacionalidad</th>
                                                                                    <th scope="col">Fecha de matrimonio</th>
                                                                                    <th scope="col">Ciudad donde se casaron</th>
                                                                                    <th scope="col">Pais donde se casaron</th>
                                                                                    <th scope="col">En USA</th>
                                                                                    <th scope="col">Incluir persona</th>
                                                                                    <th scope="col">Ciudad Actual</th>
                                                                                    <th scope="col">Pais Actual</th>
                                                                                    <th scope="col">Archivos</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items6} setItems={setItems6} localStorageItem={'items6'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth">
                                                7. Información hijos
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items7.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseEigth" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm7(onSubmitForm7)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                // items7.length == 0 && (
                                                                <>
                                                                    <div className="row g-3">
                                                                        <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                            <h5>Tenga en cuenta</h5>
                                                                            <p>Todo hijo soltero menor de 21 años puede ser incluido dentro de tu proceso como beneficiario siempre y cuando este dentro de estados unidos y haya ingresado de la misma manera en la que tu lo hiciste.</p>
                                                                        </div>
                                                                        <div className="col-sm-6">
                                                                            <label htmlFor="hijos" className="form-label">Tiene hijos?</label>
                                                                            <select
                                                                                {...registerForm7("hijos", { required: "Debe seleccionar si tiene hijos" })}
                                                                                onChange={(e) => {
                                                                                    showCantidadHijos(e.target.value);
                                                                                    registerForm7("hijos").onChange(e);
                                                                                }}
                                                                                className={`form-select ${errors7.hijos ? 'is-invalid' : ''}`} id="hijos" required=""
                                                                            >
                                                                                <option value="">Seleccione...</option>
                                                                                <option value="si">Si</option>
                                                                                <option value="no">No</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors7.hijos?.message}
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            showCantHijos ? (
                                                                                <div className="col-sm-6">
                                                                                    <label htmlFor="cantidadhijos" className="form-label">Cuantos Hijos?</label>
                                                                                    <input {...registerForm7("cantidadhijos", { required: "El campo hijos es obligatorio" })} onBlur={cantidadHijos} type="number" className={`form-control ${errors7.cantidadhijos ? 'is-invalid' : ''}`} id="cantidadhijos" placeholder="" />
                                                                                    <div className="invalid-feedback">
                                                                                        {errors7.cantidadhijos?.message}
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="col-sm-6"></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                    {
                                                                        [...Array(cantHijos)].map((_, i) => (
                                                                            <fieldset className="border rounded-3 mt-2 p-3" key={i}>
                                                                                <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1rem" }} >Información hijo #{i + 1}</legend>
                                                                                <div className="row g-3">
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="primerNombre" className="form-label">Primer nombre</label>
                                                                                        <input {...registerForm7(`primerNombre[${i}]`, { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors7.primerNombre?.[i] && errors7.primerNombre[i] ? 'is-invalid' : ''}`} id="primerNombre" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.primerNombre?.[i] && errors7.primerNombre[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="segundoNombre" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                                        <input {...registerForm7(`segundoNombre[${i}]`)} type="text" className="form-control" id="segundoNombre" placeholder="" defaultValue="" />
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="primerApellido" className="form-label">Primer apellido</label>
                                                                                        <input {...registerForm7(`primerApellido[${i}]`, { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors7.primerApellido?.[i] && errors7.primerApellido[i] ? 'is-invalid' : ''}`} id="primerApellido" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.primerApellido?.[i] && errors7.primerApellido?.[i] && errors7.primerApellido[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="segundoApellido" className="form-label">Segundo apellido</label>
                                                                                        <input {...registerForm7(`segundoApellido[${i}]`, { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors7.segundoApellido?.[i] && errors7.segundoApellido[i] ? 'is-invalid' : ''}`} id="segundoApellido" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.segundoApellido?.[i] && errors7.segundoApellido[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="fechaNacimiento" className="form-label">Fecha de nacimiento</label>
                                                                                        <input {...registerForm7(`fechaNacimiento[${i}]`, { required: "La fecha de nacimiento es obligatoria" })} type="date" className={`form-control ${errors7.fechaNacimiento?.[i] && errors7.fechaNacimiento[i] ? 'is-invalid' : ''}`} id="fechaNacimiento" placeholder="" max={maxDate} />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.fechaNacimiento?.[i] && errors7.fechaNacimiento[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="ciudadNacimiento" className="form-label">Ciudad de nacimiento</label>
                                                                                        <input {...registerForm7(`ciudadNacimiento[${i}]`, { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors7.ciudadNacimiento?.[i] && errors7.ciudadNacimiento[i] ? 'is-invalid' : ''}`} id="ciudadNacimiento" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.ciudadNacimiento?.[i] && errors7.ciudadNacimiento[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="paisNacimiento" className="form-label">Pais de nacimiento</label>
                                                                                        <input {...registerForm7(`paisNacimiento[${i}]`, { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors7.paisNacimiento?.[i] && errors7.paisNacimiento[i] ? 'is-invalid' : ''}`} id="paisNacimiento" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.paisNacimiento?.[i] && errors7.paisNacimiento[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="nacionalidad" className="form-label">Nacionalidad</label>
                                                                                        <input {...registerForm7(`nacionalidad[${i}]`, { required: "La nacionalidad es obligatoria" })} type="text" className={`form-control ${errors7.nacionalidad?.[i] && errors7.nacionalidad[i] ? 'is-invalid' : ''}`} id="nacionalidad" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.nacionalidad?.[i] && errors7.nacionalidad[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="estadoMarital" className="form-label">Estado marital</label>
                                                                                        <select {...registerForm7(`estadoMarital[${i}]`, { required: "El estado marital es obligatorio" })} className={`form-select ${errors7.estadoMarital?.[i] && errors7.estadoMarital[i] ? 'is-invalid' : ''}`} id="estadoMarital" required="">
                                                                                            <option value="">Seleccione...</option>
                                                                                            <option value="soltero/a">Soltero/a</option>
                                                                                            <option value="casado/a">Casado/a</option>
                                                                                            <option value="divorciado/a">Divorciado/a</option>
                                                                                            <option value="viudo/a">Viudo/a</option>
                                                                                        </select>
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.estadoMarital?.[i] && errors7.estadoMarital[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-sm-3">
                                                                                        <label htmlFor="enUsa" className="form-label">Se encuentra en los Estados Unidos?</label>
                                                                                        <select
                                                                                            {...registerForm7(`enUsa[${i}]`, { required: "Debe seleccionar si se encuentra en los Estado Unidos" })}
                                                                                            onChange={(e) => {
                                                                                                showEnUsaHijos(i, e.target.value);
                                                                                                registerForm7(`enUsa[${i}]`).onChange(e);
                                                                                            }}
                                                                                            className={`form-select ${errors7.enUsa?.[i] && errors7.enUsa[i] ? 'is-invalid' : ''}`} id={`enUsa-${i}`} required=""
                                                                                        >
                                                                                            <option value="">Seleccione...</option>
                                                                                            <option value="si">Si</option>
                                                                                            <option value="no">No</option>
                                                                                        </select>
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.enUsa?.[i] && errors7.enUsa[i]?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        enUsa?.[i] && enUsa[i].value === "si" && (
                                                                                            <div className="col-sm-3">
                                                                                                <label htmlFor="incluirPersona" className="form-label">Deseas incluirla dentro de tu proceso?</label>
                                                                                                <select
                                                                                                    {...registerForm7(`incluirPersona[${i}]`, { required: "Debe seleccionar si se encuentra en los Estado Unidos" })}
                                                                                                    onChange={(e) => {
                                                                                                        showAdjuntosHijos(i, e.target.value);
                                                                                                        registerForm7(`incluirPersona[${i}]`).onChange(e);
                                                                                                    }}
                                                                                                    className={`form-select ${errors7.incluirPersona?.[i] && errors7.incluirPersona[i] ? 'is-invalid' : ''}`} id={`incluirPersona-${i}`}                                                                                                     >
                                                                                                    <option value="">Seleccione...</option>
                                                                                                    <option value="si">Si</option>
                                                                                                    <option value="no">No</option>
                                                                                                </select>
                                                                                                <div className="invalid-feedback">
                                                                                                    {errors7.incluirPersona?.[i] && errors7.incluirPersona[i]?.message}                                                                                                    </div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        enUsa?.[i] && enUsa[i].value === "no" || enusaAdjuntos?.[i] && enusaAdjuntos[i].value === "no" ? (
                                                                                            <>
                                                                                                <div className="col-sm-3">
                                                                                                    <label htmlFor="ciudadActual" className="form-label">Ciudad donde vive actualmente</label>
                                                                                                    <input {...registerForm7(`ciudadActual[${i}]`, { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors7.ciudadActual?.[i] && errors7.ciudadActual[i] ? 'is-invalid' : ''}`} id="ciudadActual" placeholder="" />
                                                                                                    <div className="invalid-feedback">
                                                                                                        {errors7.ciudadActual?.[i] && errors7.ciudadActual[i]?.message}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="col-sm-3">
                                                                                                    <label htmlFor="paisActual" className="form-label">Pais donde vive actualmente</label>
                                                                                                    <input {...registerForm7(`paisActual[${i}]`, { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors7.paisActual?.[i] && errors7.paisActual[i] ? 'is-invalid' : ''}`} id="paisActual" placeholder="" />
                                                                                                    <div className="invalid-feedback">
                                                                                                        {errors7.paisActual?.[i] && errors7.paisActual[i]?.message}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        ) : (<></>)
                                                                                    }
                                                                                    {
                                                                                        enusaAdjuntos?.[i] && enusaAdjuntos[i].value === "si" && (
                                                                                            <fieldset className="border rounded-3 mt-2 p-3">
                                                                                                <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1.1rem" }} >Adjuntar archivos</legend>
                                                                                                <div className="bd-callout bd-callout-info shadow-sm mt-0" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                                    <h5>Tenga en cuenta</h5>
                                                                                                    <p>Subir los documentos correspondientes que apliquen para su caso</p>
                                                                                                </div>
                                                                                                <div className='row'>
                                                                                                    <div className="mb-3 col-sm-6">
                                                                                                        <label htmlFor="id" className="form-label">ID</label>
                                                                                                        <input {...registerForm7(`id[${i}]`)} className={`form-control`} type="file" id="id" />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-sm-6">
                                                                                                        <label htmlFor="pasaporte" className="form-label">Pasaporte</label>
                                                                                                        <input {...registerForm7(`pasaporte[${i}]`)} className={`form-control`} type="file" id="pasaporte" />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-sm-6">
                                                                                                        <label htmlFor="visa" className="form-label">Visa</label>
                                                                                                        <input {...registerForm7(`visa[${i}]`)} className={`form-control`} type="file" id="visa" />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-sm-6">
                                                                                                        <label htmlFor="documentosFrontera" className="form-label">Documentos entregados en frontera</label>
                                                                                                        <input {...registerForm7(`documentosFrontera[${i}]`)} className={`form-control`} type="file" id="documentosfrontera" />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-sm-6">
                                                                                                        <label htmlFor="parole" className="form-label">parole</label>
                                                                                                        <input {...registerForm7(`parole[${i}]`)} className={`form-control`} type="file" id="parole" />
                                                                                                    </div>
                                                                                                    <div className="mb-3 col-sm-6">
                                                                                                        <label htmlFor="actaNacimiento" className="form-label">Acta de nacimiento</label>
                                                                                                        <input {...registerForm7(`actaNacimiento[${i}]`)} className={`form-control`} type="file" id="actaNacimiento" />
                                                                                                    </div>
                                                                                                    <div className="mb-3">
                                                                                                        <label htmlFor="actaMatrimonio" className="form-label">Acta de matrimonio</label>
                                                                                                        <input {...registerForm7(`actaMatrimonio[${i}]`)} className={`form-control`} type="file" id="actaMatrimonio" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </fieldset>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </fieldset>
                                                                        ))
                                                                    }
                                                                    <hr className="my-4" />

                                                                    <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                        <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                            <span className="bi bi-plus h4 m-0"></span>
                                                                            <span>Agregar</span>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                // )
                                                            }
                                                        </form>
                                                        {
                                                            items7.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Hijos</th>
                                                                                <th scope="col">Primer nombre</th>
                                                                                <th scope="col">Segundo nombre</th>
                                                                                <th scope="col">Primer apellido</th>
                                                                                <th scope="col">Segundo apellido</th>
                                                                                <th scope="col">Fecha de nacimiento</th>
                                                                                <th scope="col">Ciudad de nacimiento</th>
                                                                                <th scope="col">Pais de nacimiento</th>
                                                                                <th scope="col">Nacionalidad</th>
                                                                                <th scope="col">Estado marital</th>
                                                                                <th scope="col">En USA</th>
                                                                                <th scope="col">Incluir persona</th>
                                                                                <th scope="col">Ciudad donde vive</th>
                                                                                <th scope="col">Pais donde vive</th>
                                                                                <th scope="col">Archivos</th>
                                                                                <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayLocalStorageData items={items7} setItems={setItems7} localStorageItem={'items7'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                8. Fecha de salida del pais
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items8.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm8(onSubmitForm8)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items8.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="dia" className="form-label">Dia</label>
                                                                                <input {...registerForm8("dia", { required: "El dia de salida del pais es obligatorio" })} type="number" className={`form-control ${errors8.dia ? 'is-invalid' : ''}`} id="dia" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors8.dia?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="mes" className="form-label">Mes</label>
                                                                                <select {...registerForm8("mes", { required: "El mes de salida del pais es obligatorio" })} className={`form-select ${errors8.mes ? 'is-invalid' : ''}`} id="mes">
                                                                                    <option value="">--Seleccione un mes--</option>
                                                                                    <option value="01">Enero</option>
                                                                                    <option value="02">Febrero</option>
                                                                                    <option value="03">Marzo</option>
                                                                                    <option value="04">Abril</option>
                                                                                    <option value="05">Mayo</option>
                                                                                    <option value="06">Junio</option>
                                                                                    <option value="07">Julio</option>
                                                                                    <option value="08">Agosto</option>
                                                                                    <option value="09">Septiembre</option>
                                                                                    <option value="10">Octubre</option>
                                                                                    <option value="11">Noviembre</option>
                                                                                    <option value="12">Diciembre</option>
                                                                                </select>
                                                                                <div className="invalid-feedback">
                                                                                    {errors8.mes?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="agno" className="form-label">Año</label>
                                                                                <input {...registerForm8("agno", { required: "El año de salida del pais es obligatorio" })} type="number" className={`form-control ${errors8.agno ? 'is-invalid' : ''}`} id="agno" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors8.agno?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />
                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items8.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Dia</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items8} setItems={setItems8} localStorageItem={'items8'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                9. Religión
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items9.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseSeven" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm9(onSubmitForm9)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items9.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="religion" className="form-label">Religión</label>
                                                                                <input {...registerForm9("religion", { required: "La religión es obligatoria" })} type="text" className={`form-control ${errors9.religion ? 'is-invalid' : ''}`} id="religion" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors9.religion?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items9.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Religión</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items9} setItems={setItems9} localStorageItem={'items9'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                10. Grupo Etnico
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items10.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTen" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm10(onSubmitForm10)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items10.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="grupoEtnico" className="form-label">Grupo etnico</label>
                                                                                <input {...registerForm10("grupoEtnico", { required: "El grupo etnico es obligatorio" })} type="text" className={`form-control ${errors10.grupoEtnico ? 'is-invalid' : ''}`} id="grupoEtnico" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors10.religion?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items10.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Grupo etnico</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items10} setItems={setItems10} localStorageItem={'items10'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                11. Idioma nativo
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items11.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseEleven" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm11(onSubmitForm11)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items11.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>Indique cual es su idioma de nacimiento</p>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="idioma" className="form-label">Idioma nativo</label>
                                                                                <input {...registerForm11("idioma", { required: "El idioma nativo es obligatorio" })} type="text" className={`form-control ${errors11.idioma ? 'is-invalid' : ''}`} id="idioma" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors11.idioma?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items11.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Idioma nativo</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items11} setItems={setItems11} localStorageItem={'items11'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                12. Otro idioma
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items12.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwelve" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm12(onSubmitForm12)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items12.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>Indique si habla un segundo idioma con fluidez, si aplica</p>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="idioma" className="form-label">Otro idioma</label>
                                                                                <input {...registerForm12("idioma")} type="text" className={`form-control ${errors12.idioma ? 'is-invalid' : ''}`} id="idioma" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors12.idioma?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items12.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Otro idioma</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items12} setItems={setItems12} localStorageItem={'items12'} exceptions={['id', 'codigoId', 'activo', 'fechaRegistro']} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <button className="btn btn-primary btn-lg text-center" style={{ "fontSize": "1rem" }} onClick={salir}>
                                        <span className='bi bi-box-arrow-left'></span>
                                        &nbsp;Salir
                                    </button>&nbsp;
                                    {
                                        !datosExistentes && (
                                            <button className="btn btn-danger btn-lg text-center mx-2" style={{ "fontSize": "1rem" }} onClick={limpiarFormulario}>
                                                <span className='bi bi-trash'></span>
                                                &nbsp;Limpiar formulario
                                            </button>
                                        )
                                    }
                                    {
                                        progress == 100 ? (
                                            loading ? (
                                                <button className="btn btn-success btn-lg text-center" style={{ "fontSize": "1rem" }} disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp; Guardando...
                                                </button>
                                            ) : (
                                                <button className="btn btn-success btn-lg text-center" style={{ "fontSize": "1rem" }} onClick={guadrarFormulario}>
                                                    <span className='bi bi-floppy-fill'></span>
                                                    &nbsp;Terminar formulario
                                                </button>
                                            )
                                        ) : (
                                            <button className="btn btn-secondary btn-lg text-center" style={{ "fontSize": "1rem" }} disabled>
                                                <span className='bi bi-floppy-fill'></span>
                                                &nbsp;Terminar formulario
                                            </button>
                                        )
                                    }
                                </div>

                            </main>
                        ) : (
                            <main className="vh-100 pb-5 pt-5 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                <div className="col-md-6">
                                    <div className="card border-0 shadow">
                                        <div className="card-header border-0">
                                            <h3 className='fw-bold'>Codigo formulario</h3>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmitCode(handdleValidateCode)} >
                                                <div className="input-group mb-3">
                                                    <button className="btn btn-primary btn-outline-secondary" type="button" id="button-addon2" name="Generar Código">
                                                        <i className="bi bi-upc text-white"></i>
                                                    </button>
                                                    <input {...registerCode("codigo", { required: "Desbes escribir el codigo" })} placeholder="XXXXXXXX" type="number" className={`form-control ${errorsCode.codigo ? 'is-invalid' : ''}`} id="codigo" />
                                                    <div className="invalid-feedback">
                                                        {errorsCode.codigo?.message}
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    {
                                                        loading ? (
                                                            <button className="btn btn-primary" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                &nbsp; validando...
                                                            </button>
                                                        ) : (
                                                            <button className="btn btn-primary text-center" type="submit">
                                                                Validar
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        )
                    }

                </div >
            </section >
        </>
    );
}

export default Form;